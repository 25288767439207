import * as R from 'ramda';
import React from 'react';
import { ReactiveComponent, ResultList } from '@appbaseio/reactivesearch';

import Qon from './models/Qon';

interface Props {
    docName: string;
    results: Array<Qon>;
    parentQon: Qon;
    setChecked: (item: Qon, parent_id?: string) => void;
}

class Background extends React.Component<Props> {

    getDetailRows = () => {
        const order = parseInt(this.props.parentQon.order);
        let lower = 0;
        let upper = 0;
        const range = 10;

        lower = order <= range ? 0 : order - range;
        upper = order + range;

        return this.detailsQuery(lower, upper);
    }

    detailsQuery = (lowerBoundOrder: number, upperBoundOrder: number) => {
        return {
            query: {
                bool: {
                    must: [
                        {
                            term: {
                                "pdf file name.keyword": {
                                    value: this.props.docName
                                }
                            }
                        },
                        {
                            range: {
                                order: {
                                    gte: lowerBoundOrder,
                                    lte: upperBoundOrder
                                }
                            }
                        }
                    ],
                    minimum_should_match: 0,
                    should: [
                        {
                            match_phrase: {
                                speech: {
                                    query: "on notice",
                                    slop: 2
                                }
                            }
                        },
                        {
                            match_phrase: {
                                speech: {
                                    query: "check that",
                                    slop: 5
                                }
                            }
                        },
                        {
                            match_phrase: {
                                speech: {
                                    query: "tabled",
                                    slop: 2
                                }
                            }
                        },
                        {
                            match_phrase: {
                                speech: {
                                    query: "bring back",
                                    slop: 5
                                }
                            }
                        },
                        {
                            match_phrase: {
                                speech: {
                                    query: "that information",
                                    slop: 3
                                }
                            }
                        },
                        {
                            match_phrase: {
                                speech: {
                                    query: "provide later",
                                    slop: 5
                                }
                            }
                        }
                    ]
                }
            },
            from: 0,
            size: 30, // should return at least double the range specified in getDetailRows()
            highlight: {
                pre_tags: ['<mark>'],
                post_tags: ['</mark>'],
                fields: {
                    "speech": {},
                },
                number_of_fragments: 0
            },
            sort: [
                {
                    order: {
                        order: "asc"
                    }
                }
            ]
        }
    }

    render() {
        return (
            <ReactiveComponent
                componentId={`details-${this.props.parentQon._id}`}
                defaultQuery={this.getDetailRows}
                render={({ error, loading, data }) => {
                    if (loading) {
                        return (
                            <ResultList.Content className="spicee-details">
                                <ResultList.Description>
                                    Loading results...
                                </ResultList.Description>
                            </ResultList.Content>
                        );
                    }

                    return (
                        <ResultList.Content className="spicee-details">
                            {
                                data.map((item: any) => {
                                    const { parentQon, results } = this.props;
                                    const res = R.find((i) => i._id === item._id && i.parent_id === parentQon._id, results);
                                    const checked = res && res.checked ? true : false;
                                    const highlight = item._id === parentQon._id;

                                    return (
                                        <ResultList.Description
                                            key={item._id}
                                            style={{
                                                paddingTop: '5px',
                                                paddingBottom: '5px'
                                            }}
                                            className={highlight ? "highlight" : ""}
                                        >
                                            <div className="row">
                                                <div className="col">
                                                    <div>
                                                        <b>{item.speaker}</b>
                                                    </div>
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: item.speech
                                                        }}
                                                    />
                                                </div>
                                                <div
                                                    className="col"
                                                    style={{
                                                        justifyContent: "flex-end",
                                                        display: "flex",
                                                        flex: "0 1 auto"
                                                    }}
                                                >
                                                    <label>
                                                        <input
                                                            type="checkbox"
                                                            className={`check-custom ${highlight ? "highlight" : ""}`}
                                                            checked={checked}
                                                            onChange={() => this.props.setChecked(item, parentQon._id)}
                                                        />
                                                        <span className="check-toggle"></span>
                                                    </label>
                                                </div>
                                            </div>
                                            {!highlight && <hr />}
                                        </ResultList.Description>
                                    );
                                })
                            }
                        </ResultList.Content>
                    );
                }}
            />
        );
    }
}

export default Background;
