import React from 'react';
import dots from './icons/dots.svg';

interface Props {
    loading: boolean;
    text: string;
}

class Background extends React.Component<Props> {

    render() {
        const { loading, text } = this.props;

        return (
            <div className="spicee-background">
                <img src={dots} className={`spicee-logo background ${loading ? "spin-logo" : ""}`} alt="logo" />
                <span>{text}</span>
            </div>
        );
    }
}

export default Background;
