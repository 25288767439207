import React, { Component } from 'react';
import Select from 'react-select';
import { BaseUrl } from './App';
import Dropzone from './Dropzone';
import Progress from './Progress';
import './Upload.css';
import { ActionMeta, ValueType, OptionsType } from 'react-select/src/types';
import { Styles } from 'react-select/src/styles';

interface Props {
    showDialog: boolean;
    hideDialog: (hide?: boolean) => void;
}

type Category = { value: string, label: string }

const uploadCategories: OptionsType<Category> = [
    { value: 'additional-estimates', label: 'Additional Estimates' },
    { value: 'budget-estimates', label: 'Budget Estimates' },
    { value: 'supplementary-budget-estimates', label: 'Supplementary Budget Estimates' }
];

interface State {
    error: {
        state: boolean;
        message: string;
    };
    files: Array<File>;
    selectedCategory: Category;
    success: boolean;
    uploading: boolean;
    uploadProgress: {
        state: string;
        percentage: number;
        filesUploaded: number;
    };
}

class Upload extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            files: [],
            uploading: false,
            uploadProgress: {
                state: "",
                percentage: 0,
                filesUploaded: 0,
            },
            selectedCategory: uploadCategories[0],
            success: false,
            error: {
                state: false,
                message: ""
            }
        };
    }

    cancel = (event?: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        this.props.hideDialog(true);
        this.setState({
            files: [],
            uploading: false,
            uploadProgress: {
                state: "",
                percentage: 0,
                filesUploaded: 0,
            },
            selectedCategory: uploadCategories[0],
            success: false,
            error: {
                state: false,
                message: ""
            }
        });
    }

    keyboardEvent = (event: KeyboardEvent): any => {
        if (event.keyCode === 27) {
           this.cancel();
        }
    }

    handleCategoryChange = (selectedOption: ValueType<Category>, action: ActionMeta) => {
        this.setState({ selectedCategory: Array.isArray(selectedOption) ? selectedOption[0] : selectedOption });
    };

    onValidateFile = (state: boolean, error: string) => this.setState({ error: { state, message: error }});

    componentDidMount() {
        document.addEventListener("keydown", this.keyboardEvent, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.keyboardEvent, false);
    }


    // fileNameValid = (file_name: string) => {
    //     this.state.files.forEach(current_file => {
    //         if (file_name===current_file.name) {
    //             return false;
    //         }
    //     })
    // }

    onFilesAdded = (files: Array<File>) => {
        //File name uniqueness validation
        if(files.every(file => this.state.files.every(curr_file => file.name !== curr_file.name))) {
            this.setState((prevState) => ({
                files: prevState.files.concat(files)
            }));
        } else {
            this.onValidateFile(true, 'File names must be unique');
        }
    }

    uploadFiles = async () => {
        this.setState({
            uploadProgress: {
                state: "",
                percentage: 0,
                filesUploaded: 0,
            },
            uploading: true,
            error: {
                state: false,
                message: ""
            }
        });

        const promises: Array<Promise<any>> = [];
        this.state.files.forEach(file => {
            promises.push(this.sendRequest(file));
        });

        try {
            await Promise.all(promises);
            this.setState({ success: true, uploading: false });
        } catch (err) {
            this.setState({
                files: [],
                error: {
                    state: true,
                    message: err,
                },
                success: false,
                uploading: false
            });
        }
    }

    sendRequest = (file: File): Promise<any> => {
        return new Promise((resolve, reject) => {
            let req = new XMLHttpRequest();

            req.upload.addEventListener("progress", event => {
                if (event.lengthComputable) {
                    this.setState((prevState) => {
                        const noOfFiles = prevState.files.length;
                        const pct = Math.round(((event.loaded / event.total) * 100) * (prevState.uploadProgress.filesUploaded / noOfFiles));
                        return {
                            uploadProgress: {
                                filesUploaded: prevState.uploadProgress.filesUploaded,
                                state: "pending",
                                percentage: pct
                            }
                        };
                    });
                }
            });

            req.onloadend = () => {
                if (req.status !== 200) {
                    return reject(req.response ? req.response.error : "");
                } else {
                    return resolve(req.response);
                }
            }

            req.upload.addEventListener("load", () => {
                this.setState((prevState) => {
                    const noOfFiles = prevState.files.length;
                    const uploadedFiles = prevState.uploadProgress.filesUploaded + 1;

                    if (noOfFiles === uploadedFiles) {
                        return {
                            uploadProgress: {
                                ...prevState.uploadProgress,
                                state: "done",
                                percentage: 100,
                                filesUploaded: uploadedFiles
                            }
                        };
                    } else {
                        return {
                            uploadProgress: {
                                ...prevState.uploadProgress,
                                filesUploaded: uploadedFiles,
                                percentage: Math.round(uploadedFiles / noOfFiles * 100)
                            }
                        };
                    }
                });
            });

            req.upload.addEventListener("error", () => reject('internal server error'));

            const formData = new FormData();
            formData.append(this.state.selectedCategory.value, file, file.name);

            req.open("POST", `${BaseUrl}upload`);
            req.responseType = 'json';
            req.send(formData);
        });
    }

    renderProgress = () => {
        const { success, uploadProgress, uploading } = this.state;
        if (uploading || success) {
            return (
                <div className="spicee-progress-wrapper">
                    <Progress progress={uploadProgress ? uploadProgress.percentage : 0} />
                    <i
                        className="material-icons spicee-check-icon"
                        style={{ visibility: uploadProgress.state === "done" ? "visible" : "hidden" }}
                    >
                        check
                    </i>
                </div>
            );
        }
    }

    render() {
        const { showDialog } = this.props;
        const { error, files, selectedCategory, success, uploading, uploadProgress } = this.state;

        let uploadText = "";

        if (error.state) {
            uploadText = "Error";
        } else if (success) {
            uploadText = "Upload complete!";
        } else if (uploading) {
            uploadText = "Uploading...";
        } else if (files.length > 0) {
            uploadText = `Total files to upload: ${files.length}`;
        }

        const dropDownStyle: Partial<Styles> = {
            option: (provided, state) => ({
                ...provided,
                "&:hover": {
                    backgroundColor: "#eeeeee"
                },
                backgroundColor: state.isSelected ? "#fafafa" : "ffffff",
                color: '#424242'
            }),
            singleValue: (provided, state) => ({
                ...provided,
                color: state.isDisabled ? "#888888" : "#424242"
            }),
            control: (provided, state) => {
                return {
                    ...provided,
                    "&:hover": {
                        borderColor: "#f2f2f2"
                    },
                    boxShadow: "none",
                    border: "2px solid #f2f2f2",
                    backgroundColor: state.isDisabled ? "#eeeeee" : "#ffffff",
                    borderRadius: 5,
                    borderColor: "green",
                    transition: "initial"
                };
            },
            indicatorSeparator: (provided, state) => ({
                ...provided,
                visibility: "hidden"
            }),
            dropdownIndicator: (provided, state) => {
                return {
                    ...provided,
                    "&:hover": {
                        color: state.isDisabled ? "#888888" : "#75CDF3"
                    },
                    color: state.isDisabled ? "#888888" : "#75CDF3",
                    backgroundColor: state.isDisabled ? "#eeeeee" : "#ffffff",
                    fontSize: "24px"
                };
            },
        }

        return (
            <div className={`spicee-overlay ${showDialog ? "show" : ""}`} >
                <div className={`spicee-upload-card ${showDialog ? "show" : ""}`}>
                    <div className="spicee-upload">
                        <div className="row">
                            <div className="col justify-center">
                                <span className="spicee-upload-title">Upload Hansard Documents</span>
                            </div>
                            <div className="col">
                                <Select
                                    autoFocus={true}
                                    isClearable={false}
                                    isSearchable={false}
                                    isDisabled={uploading || success}
                                    value={selectedCategory}
                                    onChange={this.handleCategoryChange}
                                    options={uploadCategories}
                                    styles={dropDownStyle}
                                />
                            </div>
                        </div>
                        <div className="spicee-upload-content">
                            <div>
                                <Dropzone
                                    onValidateFile={this.onValidateFile}
                                    onFilesAdded={this.onFilesAdded}
                                    disabled={uploading || success}
                                />
                            </div>
                            <div className="spicee-upload-files">
                                <div className="spicee-upload-row">
                                    <span className={`spicee-upload-subtitle ${error.state ? "error" : ""}`}>{uploadText}</span>
                                    {this.renderProgress()}
                                </div>
                                <div className="spicee-upload-row" style={{ height: "initial"}}>
                                    <span className="spicee-upload-subtitle">{uploading || success ? `${uploadProgress.percentage}%` : ""}</span>
                                    {error.state && <p className="spicee-upload-subtitle">{error.message}</p>}
                                </div>
                            </div>
                        </div>
                        <div className="spicee-upload-actions">
                            <div className="spicee-button clear"
                                style={{
                                    marginRight: '10px'
                                }}
                                onClick={this.cancel}
                            >
                                Cancel
                            </div>
                            {this.state.files.length !== 0 && !success && !error.state &&
                                <div className={`spicee-button ${uploading ? "disabled" : ""}`}
                                    onClick={this.uploadFiles}
                                >
                                    Upload
                                </div>
                            }
                            {success &&
                                <div className="spicee-button done"
                                    onClick={this.cancel}
                                >
                                    Done
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Upload;
