import React from 'react';
import { ReactiveBase, StateProvider } from '@appbaseio/reactivesearch';
import logo from './icons/logo.svg';
import './App.css';

import Search from './Search';
import Results from './Results';
import Background from './Background';
import Upload from './Upload';

export const BaseUrl = window.location.href;

interface State {
  showUpload: boolean;
}

class App extends React.Component<any, State> {

  constructor(props: any) {
    super(props);
    this.state = {
      showUpload: false
    };
  }

  clearStorage = () => localStorage.clear();

  getStoreValue = (key: string) => {
    const str = localStorage.getItem(key);
    return str ? JSON.parse(str) : undefined;
  }

  setStoreValue = (key: string, value: any) => {
    // if (key === Store.DocKey && this.getStoreValue(Store.DocKey) !== value) {
    //   localStorage.removeItem(Store.CheckedResultsListKey);
    // }
    localStorage.setItem(key, JSON.stringify(value));
  }

  toggleUploadDialog = (hide?: boolean) => {
    this.setState((prevState) => {
      const show = hide ? false : !prevState.showUpload;

      if (show) {
        document.body.classList.add("no-scroll");
      } else {
        document.body.classList.remove("no-scroll");
      }

      return { showUpload: show };
    });
  }

  render() {
    return (
      <ReactiveBase
        url={BaseUrl}
        app="hansard-senate-estimates"
        theme={{
            typography: {
              fontFamily: '"PT Sans", sans-serif',
              fontSize: '16px',
            },
            colors: {
              textColor: '#424242',
              primaryTextColor: '#ffffff',
              primaryColor: '#06A6D8',
              titleColor: '#222222',
              alertColor: '#75CDF3',
            },
          }
        }
      >
        <div className="spicee-root">
          <header className="spicee-header">
            <div className="spicee-nav">
              <div className="spicee-nav-item">
                <img src={logo} className="spicee-logo" alt="logo" />
              </div>
              <div className="spicee-nav-item header">
                <div>Search</div>
              </div>
              <div className="spicee-nav-item upload">
                {/* <i className="spicee-menu-icon material-icons">menu</i> */}
                {/* <div className={`spicee-button upload`} onClick={() => this.toggleUploadDialog()}>
                    <span style={{ marginRight: '10px' }}>Upload</span>
                    <i className={`spicee-menu-icon material-icons`}>cloud_upload</i>
                </div> */}
              </div>
            </div>
          </header>

          <Upload
            hideDialog={this.toggleUploadDialog}
            showDialog={this.state.showUpload}
          />

          <StateProvider
            componentIds={["DateFilter", "CommitteeFilter", "HearingFilter", "DocFilter"]}
            includeKeys={['isLoading', 'value', 'hits', 'aggregations', 'error']}
            render={({ searchState }) => {
              const loading = searchState.DateFilter.isLoading ||
                              searchState.CommitteeFilter.isLoading ||
                              searchState.HearingFilter.isLoading ||
                              searchState.DocFilter.isLoading;
              const resultText = loading ? "Loading..." : !searchState.DocFilter.value ? "Filter for a Hansard Document" : "results...";
              return (
                <div className="spicee-container">
                  <Search
                    clearStorage={this.clearStorage}
                    getValue={this.getStoreValue}
                    setValue={this.setStoreValue}
                  />
                  {(loading || !searchState.DocFilter.value) &&
                    <Background
                      loading={loading}
                      text={resultText}
                    />
                  }
                  <div className="spicee-body">
                    {!loading && searchState.DocFilter.value &&
                      <Results
                        docName={searchState.DocFilter.value}
                        clearStorage={this.clearStorage}
                        getValue={this.getStoreValue}
                        setValue={this.setStoreValue}
                      />
                    }
                  </div>
                </div>
              )
            }}
          />
        </div>
      </ReactiveBase>
    );
  }
}

export default App;
