import React, { Component } from 'react'
import './Progress.css'

interface Props {
    progress: number;
}

class Progress extends Component<Props> {

    constructor(props: Props) {
        super(props)
        this.state = {};
    }

    render() {
        return (
            <div className="spicee-progress-bar">
                <div
                    className="spicee-progress"
                    style={{ width: this.props.progress + '%' }}
                />
            </div>
        )
    }
}

export default Progress;
