import React from 'react';
import { SelectedFilters, SingleDropdownList } from '@appbaseio/reactivesearch';
import * as Store from './models/Store';

interface Props {
    clearStorage: () => void;
    getValue: (key: string) => any;
    setValue: (key: string, value: any) => void;
}

class Search extends React.Component<Props> {

    dropDownItem = (label: string, count: number) => (
        <div>
            {label}
            {count &&
                <span style={{
                    marginLeft: 10, color: '#4F91AE'
                }}>
                    [{count}]
                </span>
            }
        </div>
    )

    render() {
        return (
            <div className="spicee-search-card">
                <div className="row">
                    <div className="col">
                        <SingleDropdownList
                            componentId="DateFilter"
                            className="spicee-dropdown"
                            dataField="financial year.keyword"
                            title="Financial Year"
                            sortBy="desc"
                            showMissing={false}
                            filterLabel="Financial Year"
                            selectAllLabel="All Years"
                            defaultValue={this.props.getValue(Store.DateKey)}
                            onValueChange={(v) => this.props.setValue(Store.DateKey, v)}
                            react={{
                                and: ["CommitteeFilter", "HearingFilter", "DocFilter"]
                            }}
                            renderItem={(label, count, isSelected) => {
                                return this.dropDownItem(label, count)
                            }}
                        />
                    </div>
                    <div className="col">
                        <SingleDropdownList
                            componentId="CommitteeFilter"
                            className="spicee-dropdown"
                            dataField="committee name.keyword"
                            title="Committee"
                            sortBy="asc"
                            showMissing={false}
                            filterLabel="Committee"
                            selectAllLabel="All Committees"
                            defaultValue={this.props.getValue(Store.CommitteeKey)}
                            onValueChange={(v) => this.props.setValue(Store.CommitteeKey, v)}
                            react={{
                                and: ["DateFilter", "HearingFilter", "DocFilter"]
                            }}
                            renderItem={(label, count, isSelected) => {
                                return this.dropDownItem(label, count)
                            }}
                        />
                    </div>
                    <div className="col">
                        <SingleDropdownList
                            componentId="HearingFilter"
                            className="spicee-dropdown"
                            dataField="phase.keyword"
                            title="Hearing Type"
                            sortBy="asc"
                            showMissing={false}
                            filterLabel="Hearing Type"
                            selectAllLabel="All Hearing Types"
                            defaultValue={this.props.getValue(Store.HearingKey)}
                            onValueChange={(v) => this.props.setValue(Store.HearingKey, v)}
                            react={{
                                and: ["DateFilter", "CommitteeFilter", "DocFilter"]
                            }}
                            renderItem={(label, count, isSelected) => {
                                return this.dropDownItem(label, count)
                            }}
                        />
                    </div>
                    <div className="col">
                        <SingleDropdownList
                            componentId="DocFilter"
                            className="spicee-dropdown"
                            dataField="pdf file name.keyword"
                            title="Hansard Document"
                            sortBy="asc"
                            showMissing={false}
                            filterLabel="Hansard Document"
                            selectAllLabel="All Documents"
                            defaultValue={this.props.getValue(Store.DocKey)}
                            onValueChange={(v) => this.props.setValue(Store.DocKey, v)}
                            react={{
                                and: ["DateFilter", "CommitteeFilter", "HearingFilter"]
                            }}
                            renderItem={(label, count, isSelected) => {
                                return this.dropDownItem(label, count)
                            }}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col flex justify-center">
                        <SelectedFilters
                            showClearAll={true}
                            onClear={this.props.clearStorage}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default Search;
