import * as R from 'ramda';
import React from 'react';
import { ReactiveList, ResultList } from '@appbaseio/reactivesearch';

import axios from 'axios';
import Papa from 'papaparse';
import { saveAs } from 'file-saver';

import { BaseUrl } from './App';
import * as Store from './models/Store';
import Qon from './models/Qon';
import ResultDetails from './ResultDetails';

const ndjson = require('ndjson');

interface Props {
    docName: string;
    clearStorage: () => void;
    setValue: (key: string, value: any) => void;
    getValue: (key: string) => any;
}

interface State {
    exporting: boolean;
    results: Array<Qon>;
}

class Results extends React.Component<Props, State> {

    constructor(props: Props) {
        super(props);
        this.state = {
            exporting: false,
            results: props.getValue(Store.CheckedResultsListKey) || []
        };
    }

    exportResults = async () => {
        this.setState((prevState) => {
            return {
                exporting: true,
                results: prevState.results
            };
        });

        const { results } = this.state;
        const exportIds = R.uniq(R.filter((r) => r.checked === true, results).map((r) => r._id));
        const exportQuery = {
            query: {
                terms: {
                    _id: exportIds
                }
            },
            from: 0,
            size: exportIds.length,
            _source: {
                includes: ["order", "page", "committee name", "portfolio name", "phase", "speaker", "speech", "log.file.path", "financial year", "date"]
            },
            sort: [{
                order: {
                    order: "asc"
                }
            }]
        };

        try {
            let serialize = ndjson.serialize();
            let queryData: any;
            serialize.on('data', function(line: any) {
                queryData = !queryData ? line : queryData + line;
            });
            serialize.write({ index: "hansard-senate-estimates" });
            serialize.write(exportQuery);
            serialize.end();

            const res = await axios.post(
                '/hansard-senate-estimates/_msearch',
                queryData,
                {
                    baseURL: BaseUrl,
                    headers: {
                        Accept: 'application/json',
                        'content-type': 'application/x-ndjson'
                }
            });

            if (res.status === 200) {
                const resRows = res.data.responses[0].hits.hits.length;
                if (resRows > 0) {
                    // format 'order' correctly so sorting works
                    const ResArray = R.map(r => ({
                        ...r,
                        order: parseInt(r.order),
                        page: parseInt(r.page)
                    }), results);

                    let csvQonArray: Array<any> = [];
                    const exportArr = res.data.responses[0].hits.hits;
                    const byOrderAsc = R.ascend(R.prop('order'));
                    const parentQons = R.sort(byOrderAsc, R.filter(r => r.parent === true && r.checked === true, ResArray));
                    const childQons = R.filter(r => !r.parent && r.checked === true, ResArray);

                    parentQons.forEach(parent => {
                        const exportParent = R.find(R.propEq('_id', parent._id))(exportArr)._source;

                        // Concat speech field
                        let fullText = '';
                        const children = R.sort(byOrderAsc, R.filter(r => r.parent_id === parent._id, childQons));
                        children.forEach(child => {
                            const exportChild = R.find(R.propEq('_id', child._id))(exportArr)._source;
                            fullText = fullText + exportChild.speaker + ': ' + exportChild.speech + '\n';
                        });

                        // format date field
                        const regex = /(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2})/;
                        const dateArray = regex.exec(exportParent.date);
                        let questionDate = exportParent.date;
                        if (dateArray) {
                            questionDate = `${+dateArray[3]}/${+dateArray[2]-1}/${+dateArray[1]}`;
                        }

                        csvQonArray.push({
                            'Subject': '',
                            'Security Certification': '',
                            'Handling Protocol': '',
                            'Group Responsible': '',
                            'Minister': '',
                            'Committee Name': exportParent["committee name"],
                            'Committee Due Date': '',
                            'Financial Year': exportParent["financial year"],
                            'Hearing Name': exportParent.phase,
                            'Outcome': '',
                            'Question Submitted By': '',
                            'Full Question Text': fullText,
                            'Question Date': questionDate,
                            'Spoken/Written': 'Spoken',
                            'Day of Hearing': '',
                            'Hansard Page': exportParent.page
                        });
                    });

                    const csvData = Papa.unparse(csvQonArray, {
                        header: true
                    });

                    /*
                    Robust/Overkill fix for old Excel not reading UTF-8 correctly
                    Replaces all instances of em dash (U+2014) with '-' (U+45)

                    console.log(csvData);
                    console.log('-------------- Replacing em dashes -------');
                    console.log(csvData.replace(/—/g, '-'));
                    console.log('--------------------------------');
                    */

                    //Add UTF-8 Byte Order Mark to top of csv to allow correct parsing by Excel
                    let utf8BOM = "\uFEFF";
                    const blob = new Blob([utf8BOM, csvData], {type: "text/plain;charset=utf-8"});
                    saveAs(blob, `${this.props.docName}.csv`);
                } else {
                    console.error(`could not read results returned from ES: ${res.data}`);
                }
            } else {
                console.error(`export error: ${res.status} - ${res.statusText}`);
            }
        } catch (error) {
            console.error(error);
        }

        this.setState((prevState) => {
            return {
                exporting: false,
                results: prevState.results
            };
        });
    }

    clearResults = () => {
        this.setState((prevState) => {
            const newResults = R.map((row) => ({
                ...row,
                checked: false,
                expanded: false
            }), prevState.results);
            this.props.setValue(Store.CheckedResultsListKey, newResults);

            return {
                exporting: false,
                results: newResults
            }
        });
    }

    defaultQuery = () => {
        return {
            query: {
                bool: {
                    minimum_should_match: 1,
                    should: [
                        {
                            match_phrase: {
                                speech: {
                                    query: "on notice",
                                    slop: 2
                                }
                            }
                        },
                        {
                            match_phrase: {
                                speech: {
                                    query: "check that",
                                    slop: 5
                                }
                            }
                        },
                        {
                            match_phrase: {
                                speech: {
                                    query: "tabled",
                                    slop: 2
                                }
                            }
                        },
                        {
                            match_phrase: {
                                speech: {
                                    query: "bring back",
                                    slop: 5
                                }
                            }
                        },
                        {
                            match_phrase: {
                                speech: {
                                    query: "that information",
                                    slop: 3
                                }
                            }
                        },
                        {
                            match_phrase: {
                                speech: {
                                    query: "provide later",
                                    slop: 5
                                }
                            }
                        }
                    ]
                }
            },
            highlight: {
                pre_tags: ['<mark>'],
                post_tags: ['</mark>'],
                fields: {
                    "speech": {},
                },
                number_of_fragments: 4,
                fragment_size: 150
            },
            sort: [
                {
                    order: {
                        order: "asc"
                    }
                }
            ]
        }
    }

    setInitialResults = (results: Array<Qon>) => {
        this.setState((prevState) => {
            let newResults = prevState.results;
            results.forEach((row) => {
                let parentIndex = R.findIndex((r) => r._id === row._id && r.parent === true, newResults);
                if (parentIndex >= 0) {
                    newResults[parentIndex] = {
                        ...newResults[parentIndex],
                        ...row
                    };
                } else {
                    newResults.push({
                        ...row,
                        parent: true
                    });
                }
            });

            this.props.setValue(Store.CheckedResultsListKey, newResults);
            return { results: newResults };
        });
    }

    setExpanded = (ev: React.MouseEvent<HTMLDivElement, MouseEvent>, id: string) => {
        this.setState((prevState) => {
            let res = R.find((r) => r._id === id && r.parent === true, prevState.results);
            if (!res) return prevState;
            res.expanded = !res.expanded ? true : !res.expanded;
            const newState = ({
                results: [
                    ...R.filter((r) => r._id !== id || r.parent !== true, prevState.results),
                    res
                ]
            });
            this.props.setValue(Store.CheckedResultsListKey, newState.results);
            return newState;
        });
    }

    setChecked = (item: Qon, parent_id?: string) => {
        this.setState((prevState) => {
            let newResults = prevState.results;

            // check child exists
            if (parent_id) {
                let childIndex = R.findIndex((r) => !r.parent && r._id === item._id && r.parent_id === parent_id, newResults);
                let checked: boolean = false;
                if (childIndex >= 0) {
                    checked = !newResults[childIndex].checked ? true : !newResults[childIndex].checked;
                    newResults[childIndex].checked = checked;
                } else {
                    checked = true;
                    newResults.push({
                        ...item,
                        checked,
                        parent_id
                    });
                }

                // update linked parent
                if (item._id === parent_id) {
                    let parentIndex = R.findIndex((r) => r._id === parent_id && r.parent === true, newResults);
                    if (parentIndex >= 0) {
                        newResults[parentIndex].checked = checked;
                    } else {
                        console.error(`could not find linked parent for id: ${parent_id}`);
                    }
                }
            } else {
                // check parent
                let parentIndex = R.findIndex((r) => r._id === item._id && r.parent === true, newResults);
                let checked: boolean = false;
                if (parentIndex >= 0) {
                    checked = !newResults[parentIndex].checked ? true : !newResults[parentIndex].checked;
                    newResults[parentIndex].checked = checked;

                    // check linked child
                    let childIndex = R.findIndex((r) => !r.parent && r._id === item._id && r.parent_id === item._id, newResults);
                    if (childIndex >= 0) {
                        newResults[childIndex].checked = checked;
                    } else {
                        newResults.push({
                            ...item,
                            checked,
                            parent_id: item._id
                        });
                    }
                } else {
                    console.error(`could not find parent QON in results: ${item}`);
                }
            }

            const newState = ({ results: newResults });
            this.props.setValue(Store.CheckedResultsListKey, newState.results);
            return newState;
        })
    }

    render() {
        const pageSize = 25;

        return (
            <ReactiveList
                componentId="Results"
                dataField="speech"
                includeFields={["order", "page", "committee name", "portfolio name", "phase", "speaker", "speech", "log.file.path", "financial year", "pdf file name"]}
                size={pageSize}
                className="spicee-reactive-list"
                defaultQuery={this.defaultQuery}
                infiniteScroll={false}
                innerClass={{
                    pagination: "spicee-pagination",
                    list: 'spicee-results-list'
                }}
                pagination={true}
                paginationAt="both"
                onData={({ data }) => data.length > 0 ? this.setInitialResults(data) : undefined}
                pages={6}
                URLParams={true}
                react={{
                    and: ["DocFilter"]
                }}
                renderResultStats={(stats) => {
                    let startRes = 0;
                    let endRes = 0;
                    if (stats.currentPage === 0) {
                        startRes = 1;
                        endRes = pageSize;
                    } else {
                        startRes = (stats.currentPage * pageSize) + 1;
                        endRes = startRes + pageSize - 1;
                    }

                    if (endRes > stats.numberOfResults) {
                        endRes = stats.numberOfResults;
                    }

                    return (
                        <div className="spicee-result-text">
                            {`Showing results ${startRes}-${endRes} of total ${stats.numberOfResults} in ${stats.time} ms`}
                        </div>
                    )
                }}
                renderError={(error: any) => {
                    return (
                        <div className="spicee-result-text">
                            Your search query failed!<br/>Error details<br/>{JSON.stringify(error)}
                        </div>
                    )
                }}
                renderNoResults={() => {
                    return (
                        <div className="spicee-result-text">
                            No results :(
                        </div>
                    )
                }}
                render={({ data, error, loading }) => {
                    const { exporting, results } = this.state;
                    const totalQons = results.filter((r) => r.parent === true && r.checked === true).length;
                    return (
                        <ReactiveList.ResultListWrapper>
                            {
                                data.map((item: any) => {
                                    const res = R.find((r) => r._id === item._id && r.parent === true, this.state.results);

                                    if (!res) return null;

                                    const expandDetails = res.expanded ? true : false;
                                    const checked = res.checked ? true : false;

                                    return (
                                        <ResultList
                                            key={item._id}
                                        >
                                            <div className={`spicee-results-card ${expandDetails ? "expanded" : ""}`}>
                                                <ResultList.Content>
                                                    <ResultList.Title>
                                                        <div className="row">
                                                            <div className="col">
                                                                {item.speaker}
                                                            </div>
                                                        </div>
                                                    </ResultList.Title>
                                                    <ResultList.Description>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div
                                                                    style={{ marginBottom: '10px' }}
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: item.speech
                                                                    }}
                                                                />
                                                            </div>
                                                            <div
                                                                className="col"
                                                                style={{
                                                                    justifyContent: "flex-end",
                                                                    display: "flex",
                                                                    flex: "0 1 auto"
                                                                }}
                                                            >
                                                                <label>
                                                                    <input type="checkbox" className="check-custom" checked={checked} onChange={() => this.setChecked(item)}/>
                                                                    <span className="check-toggle"></span>
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="row">
                                                            <div className="col">
                                                                <div><b>portfolio:</b> {item['portfolio name']}</div>
                                                                <div><b>committee:</b> {item['committee name']}</div>
                                                                <div><b>phase:</b> {item.phase}</div>
                                                                <div><b>page:</b> {item.page}</div>
                                                                {/* <div className="spicee-link">
                                                                    <span
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={()=> window.open(`${BaseUrl}archive/${item["pdf file name"]}`, "_blank")}>
                                                                        Link to Hansard PDF
                                                                    </span>
                                                                </div> */}
                                                            </div>
                                                        </div>
                                                    </ResultList.Description>
                                                </ResultList.Content>
                                                <div className="row">
                                                    <div className="col">
                                                        <div className="spicee-details-container" onClick={(e) => this.setExpanded(e, item._id)}>
                                                            <p>{expandDetails ? "Hide" : "Show"} Details</p>
                                                        </div>
                                                        {expandDetails &&
                                                            <ResultDetails
                                                                docName={this.props.docName}
                                                                results={this.state.results}
                                                                parentQon={res}
                                                                setChecked={this.setChecked}
                                                            />
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </ResultList>
                                    )
                                })
                            }
                            <footer className="spicee-footer">
                                <div className={`spicee-footer-nav ${totalQons > 0 ? "show" : ""}`}>
                                    <div className="spicee-footer-nav-item">
                                        <div className="spicee-button clear" onClick={this.clearResults}>
                                            <span style={{ marginRight: '10px' }}>Clear</span>
                                            <i className="material-icons">clear_all</i>
                                        </div>
                                    </div>
                                    <div className="spicee-footer-nav-item">
                                        <span style={{ marginRight: '10px' }}><b>Results: {totalQons}</b></span>
                                        <div className={`spicee-button ${exporting ? "disabled" : ""}`} onClick={this.exportResults}>
                                            <span style={{ marginRight: '10px' }}>{exporting ? "Exporting..." : "Export"}</span>
                                            <i className={`material-icons ${exporting ? "spin-export" : ""}`}>{exporting ? "autorenew" : "cloud_download"}</i>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </ReactiveList.ResultListWrapper>
                    )
                }}
            />
        );
    }
}

export default Results;
