import React, { Component } from 'react'
import './Dropzone.css'

interface Props {
    disabled: boolean;
    onValidateFile: (state: boolean, error: string) => void;
    onFilesAdded: (files: Array<File>) => void;
}

interface State {
    highlight: boolean;
}

const validFileTypes = ["application/pdf"];

class Dropzone extends Component<Props, State> {
    fileInputRef: React.RefObject<any>;

    constructor(props: Props) {
        super(props);
        this.state = {
            highlight: false,
        };

        this.fileInputRef = React.createRef();
    }

    openFileDialog = () => {
        if (this.props.disabled) return;
        this.fileInputRef.current.click();
    }

    fileListToArray = (list: FileList | null) => {
        if (!list) return [];
        const array: Array<File> = [];
        for (var i = 0; i < list.length; i++) {
            const file = list.item(i);
            if (file) {
                array.push(file);
            }
        }
        return array;
    }

    filesAreValid = (array: File[]) => {
        // validate file extension
        if (!array.every(file => validFileTypes.includes(file.type))) {
            this.setState({ highlight: false });
            this.props.onValidateFile(true, `File types must be ${validFileTypes.join(', ')}`);
            return false;
        }

        //validate file name format
        if (!array.every(file => {
            const fileNameArr = file.name.split('_');
            if (fileNameArr.length <= 1) {
                this.setState({ highlight: false });
                this.props.onValidateFile(true, `File name must be in the format <committee name>_<date(YYYY_MM_DD)>_<id>.pdf`);
                return false;
            }

            if (fileNameArr[1].length !== 4 ||
                fileNameArr[2].length !== 2 ||
                fileNameArr[3].length !== 2
            ) {
                this.setState({ highlight: false });
                this.props.onValidateFile(true, `File name must be in the format <committee name>_<date(YYYY_MM_DD)>_<id>.pdf`);
                return false;
            }
            return true;
        })) {
            return false;
        }

        // validate file size (max 5MB)
        const maxFileSize = 5 * 1024 * 1024;
        if (!array.every(file => file.size <= maxFileSize)) {
            this.setState({ highlight: false });
            this.props.onValidateFile(true, `File sizes must be under 5MB`);
            return false;
        }
        return true;
    }

    onFilesAdded = (evt: React.ChangeEvent<HTMLInputElement>) => {
        if (this.props.disabled) return;
        this.props.onValidateFile(false, "");
        const files = evt.target.files;

        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);

            if(!this.filesAreValid(array)) {
                return;
            };

            this.props.onFilesAdded(array);
        };
    }

    onDragOver = (evt: React.DragEvent<HTMLDivElement>) => {
        evt.preventDefault();
        if (this.props.disabled) return;
        this.setState({ highlight: true });
    }

    onDragLeave = () => this.setState({ highlight: false });

    onDrop = (ev: React.DragEvent<HTMLDivElement>) => {
        this.props.onValidateFile(false, "");
        ev.preventDefault();
        if (this.props.disabled) return;

        const files = ev.dataTransfer.files;
        if (this.props.onFilesAdded) {
            const array = this.fileListToArray(files);

            if(!this.filesAreValid(array)) {
                return;
            };

            this.props.onFilesAdded(array);
        };

        this.setState({ highlight: false });
    }

    render() {
        return (
            <div
                className={`spicee-dropzone ${this.state.highlight ? "highlight" : ""}`}
                onDragOver={this.onDragOver}
                onDragLeave={this.onDragLeave}
                onDrop={this.onDrop}
                onClick={this.openFileDialog}
                style={{ cursor: this.props.disabled ? "default" : "pointer" }}
            >
                <i className="spicee-dropzone-icon material-icons">
                    cloud_upload
                </i>
                <input
                    ref={this.fileInputRef}
                    className="spicee-file-input"
                    type="file"
                    multiple
                    onChange={this.onFilesAdded}
                />
                <span>Upload Files</span>
            </div>
        );
    }
}

export default Dropzone;
